* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.modal-background-opacity {
  pointer-events: none; /* Prevent interactions with the background */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-submit {
    @apply bg-blue-600 hover:bg-blue-800 px-4 py-0.5 rounded;
  }
  .btn-cancel {
    @apply bg-red-600 hover:bg-red-800 px-4 py-0.5 rounded;
  }

  .btn-add {
    @apply bg-blue-600 hover:bg-blue-800 px-7 py-0.5 rounded;
  }
  .btn-print {
    @apply bg-blue-600 hover:bg-blue-800 px-4 py-0.5 rounded;
  }

  .btn-delete {
    @apply bg-red-600 hover:bg-red-800 px-5 py-0.5 rounded;
  }
  .btn-archived {
    @apply bg-[#00A6DC] hover:bg-[#14627c] px-3 py-0.5 rounded;
  }

  .btn-edit {
    @apply bg-yellow-500 hover:bg-yellow-700 px-4 py-0.5 rounded;
  }

  .btn-qualify {
    @apply bg-green-500 hover:bg-green-700 px-5 py-0.5 rounded;
  }
  .btn-qualify-disabled {
    @apply bg-green-400 px-6 py-1 rounded;
  }
  .btn-disqualify {
    @apply bg-red-600 hover:bg-red-800 px-3 py-0.5 rounded;
  }
  .btn-send {
    @apply bg-blue-600 hover:bg-blue-800 px-5 py-0.5 rounded;
  }

  .personal-input {
    @apply outline-none focus:border-blue-600 focus:ring-transparent border-gray-800 border w-full text-sm rounded text-gray-800 font-medium truncate uppercase;
  }

  .personal-input::placeholder {
    text-transform: none;
  }

  .btn-filled {
    @apply bg-[#379237] py-1 px-6 text-center rounded-full text-white;
  }
  .btn-on {
    @apply bg-[#379237] py-1 px-6 text-center rounded-full text-white;
  }
  .btn-closed {
    @apply bg-gray-2 py-1 px-6 text-center rounded-full text-white;
  }

  .inputs {
    @apply outline-none focus:border-blue-600 focus:ring-transparent border-gray-800 border w-full text-sm rounded text-gray-800 font-medium first-letter:uppercase;
  }
  .inputs1 {
    @apply outline-none focus:border-blue-600 focus:ring-transparent border-gray-800 border text-sm rounded text-gray-800 font-medium first-letter:uppercase;
  }
  .error-inputs {
    @apply focus:outline-none border-red-600 focus:border-transparent border w-full text-sm rounded text-gray-800 font-medium;
  }

  .text-errors {
    @apply text-[13px] text-red-600;
  }

  .labels {
    @apply block text-base mb-1 text-gray-800 font-bold;
  }

  .tooltip-container {
    @apply absolute bg-gray-300 text-gray-800 px-0 py-0 rounded text-xs whitespace-nowrap;
  }
  .tooltip-container2 {
    @apply absolute bg-gray-300 text-gray-800 px-0 py-1 rounded text-xs;
  }
  .custom-checkbox input:checked ~ .checkmark {
    @apply bg-blue-600 border-blue-600;
  }
  .custom-checkbox input:checked ~ .checkmark .checkmark-icon {
    @apply block w-1 ml-1.5 h-4;
  }
  .custom-checkbox .checkmark-icon::before {
    content: "";
    @apply block w-full h-full border-r-2 border-b-2 border-white;
  }
}

@layer utilities {
  .row-span-7 {
    grid-row: span 7 / span 7;
  }
  .row-span-9 {
    grid-row: span 9 / span 9;
  }
  .grid-cols-24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }
  .grid-cols-36 {
    grid-template-columns: repeat(36, minmax(0, 1fr));
  }
  .col-start-16 {
    grid-column-start: 16;
  }
  .col-start-20 {
    grid-column-start: 20;
  }
  .col-start-30 {
    grid-column-start: 30;
  }
  .text-break {
    hyphens: auto;
  }

  /* Add more classes for greater row-spans if needed */
}

/* Styling the scrollbar track */
.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Styling the scrollbar thumb */
.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Styling the scrollbar on hover */
.scrollbar-thin:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-horizontal::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Styling the scrollbar thumb */
.scrollbar-horizontal::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Styling the scrollbar on hover */
.scrollbar-horizontal:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}

.scrollbar-horizontal::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
/* Adjusting the scrollbar width */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio for responsive iframe */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Hidden Scrollbar */
.hidden-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Style the scrollbar track and thumb */
.hidden-scrollbar::-webkit-scrollbar {
  width: 6px; /* Adjust this width as needed */
  background-color: white;
}

.hidden-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray; /* Hide the thumb */
}

/* .hidden-scrollbar::-webkit-scrollbar {
  display: none;
} */
